<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/front1-800.jpg')" title="FLIGHTSCOPE AVIATION" subtitle="Located at Archerfield Airport" />
    <div class="about-description">
      <p>
        Flightscope's training facility is located at <a href="https://goo.gl/maps/1jw5rC56ooyf6s4W6">643 Grumman Close Archerfield Q 4108.</a> <br><br>
      </p>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="940" height="700" id="gmap_canvas" src="https://maps.google.com/maps?q=Flightscope%20Aviation%20643%20Grumman%20Close%20Archerfield&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
      <p>
        <br>

        Archerfield Airport is Brisbane's secondary airport, located just 11kms from the Brisbane CBD. <br><br>

        Our facility is in a great location for short and easy taxiing to all runways, with quick flight times to training areas and scenic vistas of the Gold Coast, Moreton Bay, Brisbane and surrounding hinterland.<br><br>

        Located nearby is a pilot shop catering for text books, maps &amp; charts, and all of your general pilot needs.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';

#gmap_canvas {
  height: 50vh;
  width: 100%;
}

.about-description {
  display: inline-block;
  width: 80vw;
  margin: 5vh auto;
}

.flightscope-map {
  @media screen and (max-width: 600px) {
    height: 100%;
    width: 100%;
  }
  height: 50%;
  width: 50%;
  object-fit: contain;
}
</style>

<style lang="scss">
.contact-card {
  margin-bottom: 5vh;
}
</style>
